@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #FFFFFF;
    --foreground: #2C2C2C;

    --border: #C5C5C5;
    --input: #C5C5C5;

    /* --primary: 26 163 181; */
    --primary: 65 148 241;
    --primary-foreground: #FFFFFF;
    --primary-light: 198 223 251;

    --secondary: #F56868;

    --muted: #F4F4F4;
    --muted-foreground: #666666;

    --disabled: #F2F7F8;
    --disabled-foreground: #9A9A9A;

    --success: #5AC38A;
    --error: #FF0000;
  }
}

@layer base {
  html {
    @apply text-foreground tracking-tight font-medium;
    -webkit-tap-highlight-color: transparent;
    accent-color: var(--primary-color);
  }

  h3 {
    @apply text-lg font-medium;
  }

  strong {
    @apply !font-medium
  }

  input[type="checkbox"] {
    @apply rounded-sm border-gray-400 text-primary focus:outline-none focus:ring-0;
  }
  select {
    @apply appearance-none rounded-md border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-900;
  }
  textarea {
    @apply w-full appearance-none rounded-md border-gray-400 p-2 focus:border-gray-900 focus:ring-0;
  }
  th {
    @apply border font-medium;
  }
  td {
    @apply border;
  }
  b {
    @apply font-bold text-primary;
  }
  post-content strong {
    @apply font-medium;
  }
}

@layer components {
  .button {
    @apply bg-primary text-white px-2 py-1 rounded-md disabled:opacity-40
  }
}

@layer utilities {
  .underline-all p {
    line-height: 2rem;
    background-image: repeating-linear-gradient(
      to bottom,
      #fff,
      #fff calc(2rem - 1px),
      #ddd calc(2rem - 1px),
      #ddd calc(2rem)
    );
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    @apply text-[28px] font-bold leading-[135%] break-keep text-[#202020];
  }
  
  .subtitle {
    @apply text-[23px] font-bold leading-[135%] break-keep text-[#202020];
  }

  .subsubtitle {
    @apply text-[18px] font-bold leading-[135%] break-keep text-[#202020];
  }

  .highlight {
    @apply relative after:content-[''] after:h-[10px] after:bg-primary after:opacity-30 after:w-full after:inline-block after:absolute after:bottom-0 after:left-0 after:-z-10

  }
}